import React from 'react'

import ReactDOM from 'react-dom'
import Root from 'containers/Root'
import Raven from 'raven-js'

import 'babel-polyfill'
import 'isomorphic-unfetch'
import 'react-app-polyfill/ie11'

import * as serviceWorker from './service-worker'

import 'scss/index.scss'

if (!window.location.href.includes('localhost')) {
	Raven.config(
		'https://35cb8c2ad68004598c430d7b7cb01c20@o4505507149840384.ingest.us.sentry.io/4508358208913408',
		{
			environment: process.env.NODE_ENV
		}
	).install()
}

ReactDOM.render(<Root />, document?.getElementById('root'))

serviceWorker.unregister()
