import update from 'immutability-helper'

import ActionTypes from './actionTypes'

const SET_DISCOUNTS = 'SET_DISCOUNTS'
const EDIT_PROMOCODE = 'EDIT_PROMOCODE'
const CLEAN_PROMOCODE = 'CLEAN_PROMOCODE'
const SET_SUBSCRIBE = 'SET_SUBSCRIBE'
const SET_TOTAL = 'SET_TOTAL'

export const setDiscounts = (discounts) => ({ type: SET_DISCOUNTS, discounts })
export const editPromocode = (value, ticketId) => ({ type: EDIT_PROMOCODE, value, ticketId })
export const cleanPromocode = () => ({ type: CLEAN_PROMOCODE })
export const setSubscribe = (value) => ({ type: SET_SUBSCRIBE, value })
export const setTotal = (total) => ({ type: SET_TOTAL, total })

let initialState = {
	isSubscribed: true,
	ticketTypes: {},
	attendees: {},
	checkoutUrl: '',
	paymentSubmitted: false,
	orders: [],
	attendeesCheckDiscount: [],
	promocodes: {},
	total: null,
	eventLanguage: null
}

const Event = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case ActionTypes.CHANGE_EVENT_LANG:
			return {
				...state,
				eventLanguage: action.payload
			}
		case SET_TOTAL:
			return {
				...state,
				total: action.total
			}
		case SET_DISCOUNTS:
			return {
				...state,
				attendeesCheckDiscount: action.discounts
			}
		case EDIT_PROMOCODE:
			const key = action.ticketId
			const obj = {
				[action.ticketId]: { value: action.value, ticketId: action.ticketId, error: '' }
			}
			return {
				...state,
				promocodes: { ...state.promocodes, ...obj }
			}
		case CLEAN_PROMOCODE:
			return {
				...state,
				promocodes: {}
			}
		case SET_SUBSCRIBE:
			return {
				...state,
				isSubscribed: action.value
			}
		case ActionTypes.RESET:
			return { ...initialState }
		case ActionTypes.ON_CREATE_ORDER:
			/**
			 * If all tickets is free or all paid tickets is awaiting
			 * for approve - `checkoutUrl` will be empty or `null`.
			 * Set `paymentSubmitted=true` to avoid renderind of payment form
			 * Then user will be able to download tickets immediately or
			 * will display text, that mean, that attendee should await an email from
			 * organizer with approve or decline his request
			 * Reset attendees list for aviod not understanding from
			 * user and for more pure of page
			 */
			return {
				...state,
				checkoutUrl: payload.checkoutUrl,
				attendees: [],
				orders: payload.order,
				paymentSubmitted: !payload.checkoutUrl
			}
		case ActionTypes.SET_PAYMENT_SUBMITTED:
			return { ...state, paymentSubmitted: payload }
		case ActionTypes.SET_CHECKOUT_URL:
			return { ...state, checkoutUrl: payload }
		case ActionTypes.EDIT_ORDER_INFO:
			return { ...state, [payload.field]: payload.value }
		case ActionTypes.SET_ORDER_ID:
			return { ...state, order: payload }
		case ActionTypes.SET_ATTENDEES:
			if (payload) return { ...state, attendees: payload }

			/**
			 * Attendee object will simulate ticketTypes object
			 * Attendee forms will group by ticketTypes
			 * The key of this groups - is ticketTypeId
			 *
			 * Same algorithm is in questions
			 * Questions will an object, with  id of it`s question as key
			 *
			 * Id of attendee`s form should be uniquie and created with Math.random or alternative func
			 *
			 *
			 * The additional idea is to make filling forms easy and painless
			 * If user filled all forms and want to add some quantity of tickets he shouldn`t to fill this forms again
			 * We will check length of attendee`s forms for every ticketType and if it`s quantity come biggest - add new forms to current, with saving previous info
			 * If quantity come less - remove diff between current length of attendee`s forms and new quantity. Removing should be from the end of list
			 */

			const attendees = {}
			// Array of ticketType ids
			// [38,39,40,50,55]
			Object.keys(state.ticketTypes).forEach((ttId) => {
				attendees[ttId] = state.attendees[ttId] || []

				const ticketType = state.ticketTypes[ttId]
				const attendeesLength = attendees[ttId].length
				const { selected, simplePay, withPromocode } = ticketType

				if (attendeesLength === selected || (simplePay && selected !== 0 && attendeesLength !== 0))
					return null

				if (attendeesLength > selected) {
					attendees[ttId] = attendees[ttId].slice(0, selected)
					return null
				}

				// If ticketType have any active coupons -- add coupon field for attendee config

				const questionsOrder = {}
				ticketType.questionsOrder.forEach((q) => (questionsOrder[q.id] = q))

				const form = {
					questionsOrder,
					coupon: withPromocode ? { value: '' } : undefined
				}

				const generateForms = simplePay ? 1 : selected - attendeesLength

				for (let i = 0; i < generateForms; i++) {
					attendees[ttId].push({
						id: Math.random(),
						...form
					})
				}
			})
			return { ...state, attendees }
		case ActionTypes.CREATE_SEATS_ATTENDEE:
			// if (payload) return { ...state, attendees: payload }

			const { ticketTypeId, seatId, withPromocode } = payload

			const questionsOrder = {}

			const ticketType = state.ticketTypes[ticketTypeId]

			ticketType.questionsOrder.forEach((q) => (questionsOrder[q.id] = q))

			const form = {
				questionsOrder,
				coupon: ticketType.withPromocode ? { value: '' } : undefined
			}

			const newAttendees = {
				...state.attendees,

				[ticketTypeId]: [
					...state.attendees[ticketTypeId],
					{
						id: Math.random(),
						seatId: seatId,
						...form
					}
				]
			}

			return { ...state, attendees: newAttendees }

		case ActionTypes.REMOVE_ATTENDEE:
			return update(state, {
				ticketTypes: {
					[payload.ticketTypeId]: (ticketType) => {
						return {
							...ticketType,
							selected: ticketType.simplePay ? 0 : ticketType.selected - 1
						}
					}
				},

				attendees: {
					[payload.ticketTypeId]: (attendees) => [
						...attendees.slice(0, payload.attendeeIndex),
						...attendees.slice(payload.attendeeIndex + 1)
					]
				}
			})
		case ActionTypes.SET_TICKET_TYPES:
			return { ...state, ticketTypes: payload }
		case ActionTypes.EDIT_TICKET_TYPE:
			return update(state, {
				ticketTypes: {
					[payload.id]: {
						selected: {
							$set: payload.value
						}
					}
				}
			})
		case ActionTypes.EDIT_ATTENDEE_COUPON:
			return update(state, {
				attendees: {
					[payload.ticketTypeId]: {
						[payload.attendeeIndex]: {
							coupon: {
								[payload.field]: {
									$set: payload.value
								}
							}
						}
					}
				}
			})
		case ActionTypes.EDIT_ATTENDEE:
			return update(state, {
				attendees: {
					[payload.ticketTypeId]: {
						[payload.attendeeIndex]: {
							questionsOrder: {
								[payload.questionId]: {
									[payload.field]: {
										$set: payload.value
									}
								}
							}
						}
					}
				}
			})
		default:
			return state
	}
}

export default Event
