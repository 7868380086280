export default {
	ON_CREATE_ORDER: '@@event/ON_CREATE_ORDER',
	EDIT_ORDER_INFO: '@@event/EDIT_ORDER_INFO',
	SET_ATTENDEES: '@@event/SET_ATTENDEES',
	CREATE_SEATS_ATTENDEE: '@@event/CREATE_SEATS_ATTENDEE',
	REMOVE_ATTENDEE: '@@event/REMOVE_ATTENDEE',
	EDIT_ATTENDEE: '@@event/EDIT_ATTENDEE',
	EDIT_ATTENDEE_COUPON: '@@event/EDIT_ATTENDEE_COUPON',
	SET_TICKET_TYPES: '@@event/SET_TICKET_TYPES',
	EDIT_TICKET_TYPE: '@@event/EDIT_TICKET_TYPE',
	SET_ORDER_ID: '@@event/SET_ORDER_ID',
	RESET: '@@event/RESET',
	CHANGE_EVENT_LANG: '@@event/CHANGE_EVENT_LANG'
}
